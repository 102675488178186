<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <div class="form-row justify-content-between">
          <div class="col-md-2">
            <v-select
              label="title"
              required
              v-model="perPage"
              :options="perPageOptions"
              @input="setPerPageSelected"
              :clearable="false"
            />
          </div>
          <div class="">
            <input
              type="search"
              placeholder="Pesquisar..."
              class="form-control"
              v-model="filter"
            />
          </div>
        </div>
      </div>

      <b-table
        sticky-header
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-filtered-text="Nenhum registro encontrado"
        empty-text="Nenhum registro encontrado"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(name)="data">
          {{ data.item.name }}
        </template>

        <template #cell(token)="data">
          {{ data.item.token }}
        </template>

        <template #cell(actions)="data">
          <div class="d-flex-center">
            <feather-icon
              icon="TrashIcon"
              class="cursor-pointer cursor text-danger"
              size="16"
              @click="confirmDelete(data.item.uuid)"
            />
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2 mt-2 paginate-area">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="">
              Exibindo {{ rowsTable }} de {{ perPage }} com total de
              {{ totalRows }} resultados
            </span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              :limit="10"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="my-1"
              @input="getData()"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

      <b-modal
        id="modal-create-partner"
        modal-class="modal-create"
        :title="titleModal"
        centered
        hide-footer
      >
        <b-form @submit.prevent="submitCreate">
          <div class="form-row">
            <div class="col-md-12">
              <label for="">
                Nome do parceiro
                <i class="text-danger bi bi-record-circle"></i>
              </label>
              <div class="form-group">
                <b-form-input
                  v-model="item.name"
                  :class="{ 'is-invalid': $v.item.name.$error }"
                />
              </div>
            </div>
          </div>
          <div class="form-row justify-content-end modal-footer-custom">
            <div class="col-md-7 col-12">
              <button
                type="button"
                @click="$bvModal.hide('modal-create-partner')"
                class="btn btn-light float-left"
              >
                Fechar
              </button>
              <ButtonsActionsFooter
                variant="success"
                :submited="submited"
                :text="textSubmit"
                subtext="Aguarde..."
              />
            </div>
          </div>
        </b-form>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BPagination,
  BBadge,
  BModal,
  BFormGroup,
  BTooltip,
  BForm,
  BCardText,
  BFormRow,
  BContainer,
} from "bootstrap-vue";
import { mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    BCard,
    BBadge,
    BModal,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BButton,
    BPagination,
    vSelect,
    BTooltip,
    BForm,
    BCardText,
    BFormRow,
    BContainer,
    ButtonsActionsFooter,
  },
  data() {
    return {
      uuid: null,
      textSubmit: "",
      submited: false,
      filter: null,
      filterOn: [],
      currentPage: 1,
      perPage: 50,
      totalRows: 0,
      rowsTable: 0,
      perPageOptions: [
        {
          title: "50",
          value: 50,
        },
        {
          title: "100",
          value: 100,
        },
      ],
      tableColumns: [
        {
          key: "name",
          label: "Nome",
        },
        {
          key: "token",
          label: "Token",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "actions",
          label: "",
          thClass: "text-center td-actions",
          tdClass: "text-center",
          thStyle: "width: 80px",
        },
      ],
      dataItems: [],
      titleModal: "",
      item: {
        name: "",
      },
    };
  },
  validations: {
    item: {
      name: {
        required,
      },
    },
  },
  mounted() {
    this.getData();
    this.$root.$on("open:modal-create-partner", () => {
      this.textSubmit = "Cadastrar";
      this.titleModal = "Cadastrar Parceiro";
      this.$bvModal.show("modal-create-partner");
    });
  },
  beforeDestroy() {
    this.$root.$off("open:modal-create-partner");
  },
  methods: {
    modalEdit(uuid) {
      this.textSubmit = "Salvar";
      this.titleModal = "Editar";
      this.clearForm();
      this.$store.dispatch("Partner/show", uuid).then(() => {
        this.item = this.rd;
        this.uuid = this.item.uuid;

        this.item.status = this.item.status
          ? this.optionsStatus[0]
          : this.optionsStatus[1];
        this.$bvModal.show("modal-create-partner");
      });
    },
    submitCreate() {
      this.$v.$touch();
      this.submited = true;

      if (!this.$v.$error) {
        if (this.textSubmit === "Cadastrar") {
          this.$store
            .dispatch("Partner/create", this.item)
            .then(() => {
              this.getData();
              this.notifyDefault("success");
              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-partner");
                this.clearForm();
              });
            })
            .finally(() => {
              this.submited = false;
            });
        } else {
          this.$store
            .dispatch("Partner/update", { uuid: this.uuid, data: this.item })
            .then(() => {
              this.getData();

              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-partner");
                this.clearForm();
              });
            })
            .catch(() => {
              this.submited = false;
            })
            .finally(() => {
              this.submited = false;
            });
        }
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    setPerPageSelected(obj) {
      this.perPage = obj.value;
      this.getData();
    },
    clearForm() {
      this.item = {
        name: "",
        status: false,
      };
    },
    async getData() {
      this.dataItems = [];
      this.$store
        .dispatch("Partner/all", {
          currentPage: this.currentPage,
          perPage: this.perPage,
        })
        .then(() => {
          if (this._items) {
            this.dataItems = this._items.data;
            this.rowsTable = this._items.data.length;
            this.totalRows = this._items.meta.total;
            this.currentPage = this._items.meta.current_page;
          }
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    updatePage() {
      this.getData();
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Quer excluir?",
        text: "Esta ação será irreversível!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("Partner/delete", uuid).then(() => {
            this.getData();
            this.$swal({
              icon: "success",
              title: "Deletado!",
              text: "Operação realizada com sucesso.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapState("Partner", ["_items", "_item"]),
  },
};
</script>
